<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <div class="banner">
      <div class="banner-wrap clearfix">
        <div class="banner-text">
          <h3>个人中心</h3>
          <p>当前位置： <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a></p>
        </div>
      </div>
    </div>

    <div class="personal-content">
      <div class="personal-box clearfix">
        <div class="personal-left">
          <hd-img ref="leftInfo"></hd-img>
        </div>
        <div class="personal-right">
          <div class="personal-right-tit">
            <div class="top1 flex-start">
              <p class="t1-icon">!</p>
              <p class="t1-t">您现在的版本是”{{ userInfo.version.name }}“，还有{{ userInfo.daysRemaining ? userInfo.daysRemaining : 0 }}天可使用。</p>
            </div>
            <div class="top2">选择版本</div>
            <div class="top3 flex-start">
              <div class="version-item" :class="activeIndex == index ? 'activeStyle' : ''" @click="handleVersion(index)" v-for="(item, index) in dataList" :key="index">{{ item.versioName }}</div>
            </div>
            <div class="top4" v-if="nameList.length > 0">
              <div class="t4-1">含下列功能的所有权益</div>
              <div class="flex-start flex-wrap">
                <div class="t4-item" v-for="(item, index) in nameList" :key="index">· {{ item }}</div>
              </div>
            </div>
            <div class="top2">购买时长</div>
            <div class="top5">
              <div class="t5-box1 t5-item" :class="activeIndex2 == index ? 'activeStyle2' : ''" @click="activeIndex2 = index" v-for="(item, index) in dataList[activeIndex].children" :key="index">
                <div class="t5-red">超值优惠</div>
                <div class="t5-box2" :class="activeIndex2 == index ? 'activeStyle2' : ''">
                  <div class="t5-box3 flex-space-between">
                    <div>
                      <div class="t5-t1" v-if="item.priceType == 1">{{ item.duration }}月{{ dataList[activeIndex].versioName }}</div>
                      <div class="t5-t1" v-if="item.priceType == 3">{{ item.duration }}年{{ dataList[activeIndex].versioName }}</div>
                      <div class="t5-t1" v-if="item.priceType == 4">{{ item.duration }}天{{ dataList[activeIndex].versioName }}</div>
                      <div class="t5-t2" v-if="nameList.length > 0">升级后享{{ nameList.length }}项特权功能</div>
                    </div>
                    <div>
                      <div class="t5-t3">
                        ￥<span class="t5-t4">{{ item.trading }}</span>
                      </div>
                      <div class="t5-t5">原价￥{{ item.marketPrice }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="top6">购买须知：</div>
            <div class="top7">
              <div>1.本产品为虚拟服务，<span class="t7-t1">开通后不支持退款 </span></div>
              <div>2.支付成功会员权益未生效：a.重新登录本产品 b.重新登录还未生效请联系客服：0395-3393819</div>
            </div>
            <div class="top8"></div>
            <div class="top9 flex-space-between">
              <div class="t9-check">
                <el-checkbox v-model="checked"
                  >我已阅读并同意 <router-link target="_blank" to="/company/UserAgreement"><span class="t9-t1">《服务协议》</span></router-link
                  >和<router-link target="_blank" to="/company/disclaimer"><span class="t9-t1">《隐私政策》</span></router-link></el-checkbox
                >
              </div>
              <div class="t9-btn" @click="toCharge">立即购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <el-dialog title="收费" :visible.sync="setChargeDialog" width="600px" :show-close="false">
      <el-form class="dialog-form" :model="charge" ref="chargeForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="付款金额" style="color: #ff0000" class="numT">
          <span style="font-size: 25px"><span style="font-size: 14px">￥</span>{{ charge.payment ? charge.payment.toFixed(2) : "0.00" }}</span>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-radio-group v-model="charge.payWay" @input="chargePayWayInput">
            <el-radio :label="1" :disabled="WAndZ">微信</el-radio>
            <el-radio :label="2" :disabled="WAndZ">支付宝</el-radio>
            <el-radio :label="0" :disabled="assetsBalanceIsTrue">余额</el-radio><span style="font-size: 14px; color: #ff0000">（可用余额：￥{{ UserBalance.assetsBalance }}）</span>
          </el-radio-group>
        </el-form-item>
        <template v-if="charge.payWay == 0">
          <el-form-item label="支付密码" prop="payPwd"> <el-input type="password" maxlength="6" v-model="charge.payPwd" show-password style="width: 200px"></el-input><span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer" @click="getPayPwd">忘记密码？</span> </el-form-item>
        </template>
        <template v-if="charge.payWay == 1">
          <div class="flex-space-between-columns" v-if="qRcodeUrl">
            <img style="width: 140px; height: 140px" :src="qRcodeUrl" alt="Base64 Image" />
            <div>
              <span>打开微信扫码支付~ </span>
              <!-- <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span> -->
            </div>
          </div>
        </template>
        <template v-if="charge.payWay == 2">
          <div class="flex-space-between-columns" v-if="qRcodeUrlZ">
            <!-- <iframe :srcdoc="qRcodeUrlZ" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" width="200" height="200" style="overflow: hidden; margin: 10px 0"> </iframe> -->
            <img style="width: 140px; height: 140px" :src="qRcodeUrlZ" alt="Base64 Image" />
            <div>
              <span>打开支付宝扫码支付~ </span>
              <!-- <span style="font-size: 14px; color: #00afb5; margin-left: 5px; cursor: pointer">刷新</span> -->
            </div>
          </div>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('chargeForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('chargeForm')" :loading="nosucecessloading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { UserInfo } from "../../components/User/UserInfo";
import { Upgrade } from "../../components/upgrade/upgrade";
import { shoppingMall } from "../../components/shoppingMall/shoppingMall";
import { Outpatient } from "@/components/Drug/Outpatient";
var timer = "";
var loading = "";
export default {
  name: "resetPassword",
  inject: ["reload"],
  components: {
    ftCom,
    nhCom,
    hdImg,
  },
  data() {
    var UserDistributorltem = new shoppingMall(this.TokenClient, this.Services.Authorization);
    var user = new UserInfo(this.TokenClient, this.Services.Authorization);
    var upgrade = new Upgrade(this.TokenClient, this.Services.Authorization);
    var outpatientAuthorization = new Outpatient(this.TokenClient, this.Services.Authorization);
    var UserDistributorltemDrug = new shoppingMall(this.TokenClient, this.Services.Drug);
    return {
      userDomain: user,
      upgradeDomain: upgrade,
      UserDistributorMain: UserDistributorltem,
      outpatientAuthorizationDomain: outpatientAuthorization,
      UserDistributorltemDrugMain: UserDistributorltemDrug,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      activeIndex: 0,
      activeIndex2: 0,
      dataList: [{ children: [{ trading: 0 }] }],
      menuList: [],
      nameList: [],
      checked: false,
      rules: {
        payPwd: [
          { required: true, message: "请输入支付密码", trigger: "blur" },
          {
            pattern: /(^[0-9]{6}$)/,
            message: "请输入6位数字",
            trigger: "blur",
          },
        ],
        payment: [{ required: true, message: "请输入付款金额", trigger: "blur" }],
      },
      formLabelWidth: "100px",
      setChargeDialog: false,
      assetsBalanceIsTrue: false,
      WAndZ: false,
      charge: {
        id: 0,
        discountsMoney: 0, //优惠金额
        payWay: 1, //0 现金 1微信支付，2支付宝，3银行卡 4 挂账 5 其他
        payMoney: 0, //支付金额
        totalMoney: 0,
        payment: null, //输入金额
        change: 0, //找零
      },
      qRcodeUrl: "",
      weChatInfo: "",
      // timer: null,
      // timerZ: null,
      qRcodeUrlZ: "",
      nosucecessloading: false,
      UserBalance: {
        assetsBalance: 0,
      },
    };
  },
  mounted() {
    this.LoadUserInfo();
    this.getUpgradeVersion();
  },
  methods: {
    LoadUserInfo() {
      var _this = this;
      _this.userDomain.UserMes(
        function (data) {
          _this.userInfo = data.data;
          sessionStorage.setItem("userInfo", JSON.stringify(data.data));
          _this.$refs.leftInfo.getUserVersion()
        },
        function (error) {}
      );
    },
    getPayPwd() {
      const href = this.$router.resolve("../personal/resetPassword");
      window.open(href.href, "_blank");
    },
    chargePayWayInput(e) {
      if (timer) {
        clearInterval(timer);
      }
      if (loading) {
        loading.close();
      }
      this.WAndZ=false
      this.nosucecessloading = false;
      this.qRcodeUrl = "";
      this.qRcodeUrlZ = "";
    },
    AllMessages() {
      var _this = this;
      _this.UserDistributorMain.getUserDistributor(
        function (data) {
          _this.UserBalance = data.data;
          if (_this.UserBalance.assetsBalance * 1 <= _this.charge.payment) {
            _this.assetsBalanceIsTrue = true;
          } else {
            _this.assetsBalanceIsTrue = false;
          }
        },
        function (err) {
          _this.UserBalance = [];
          console.log(err);
        }
      );
    },
    toCharge() {
      if (!this.checked) {
        this.$message({
          message: "请阅读并同意用户协议和免责声明！",
          type: "warning",
        });
        return;
      }
      this.AllMessages();
      this.charge.payment = this.dataList[this.activeIndex].children[this.activeIndex2].trading;
      let versionsId = this.dataList[this.activeIndex].children[this.activeIndex2].versionsId;
      let versionsPriceId = this.dataList[this.activeIndex].children[this.activeIndex2].id;
      // 升级需要升级计算，续费不需要
      if (this.userInfo.versionId && this.userInfo.daysRemaining>0 && this.userInfo.versionId != versionsId) {
        this.orderCalculate(versionsPriceId);
      } else {
        this.setChargeDialog = true;
      }
    },
    CloseBtn(formName) {
      clearInterval(timer);
      this.qRcodeUrl = "";
      this.qRcodeUrlZ = "";
      this.WAndZ=false
      this.nosucecessloading = false;
      this.setChargeDialog = false;
      this.$refs[formName].resetFields();
    },
    /**
     * 验证支付密码
     * @param loading
     */
    checkUserAccount(form) {
      var _this = this;
      _this.outpatientAuthorizationDomain.getIsSetPayPassword(
        async function (data) {
          let res = await _this.getIsPayPassword();
          if (res) {
            // 支付
            _this.submitOrder(form);
          } else {
            loading.close();
            _this.nosucecessloading = false;
            _this.$message({
              type: "warning",
              message: "支付密码错误",
            });
          }
        },
        function (error) {
          loading.close();
          _this.nosucecessloading = false;
          _this
            .$confirm("未设置支付密码？去设置", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              const href = _this.$router.resolve("../personal/resetPassword");
              window.open(href.href, "_blank");
            });
        }
      );
    },
    getIsPayPassword() {
      var _this = this;
      return new Promise((resolve, reject) => {
        _this.outpatientAuthorizationDomain.getIsPayPassword(
          {
            payPassword: _this.charge.payPwd,
          },
          function (data) {
            resolve(true);
          },
          function (error) {
            resolve(false);
          }
        );
      });
    },
    submitOrder(form) {
      var _this = this;
      _this.upgradeDomain.UnifiedorderBrowser(
        form,
        function (data) {
          _this.weChatInfo = data.data;
          if (_this.charge.payWay == 0) {
            // 余额
            _this.weChatInfo.orderNo = data.data.orderNum;
          } else if (_this.charge.payWay == 1) {
            // 微信
            loading.close();
            _this.qRcodeUrl = "data:image/jpeg;base64," + data.data.qRcodeBase64;
          } else if (_this.charge.payWay == 2) {
            // 支付宝
            loading.close();
            //_this.qRcodeUrlZ = data.data.alipayData;
            _this.qRcodeUrlZ = "data:image/jpeg;base64," + data.data.qRcodeBase64;
          }
          _this.toCheckOrder();
        },
        function (error) {
          loading.close();
          let jsonObj = JSON.parse(error.msg);
          _this.$message({
            message: jsonObj.msg,
            type: "error",
          });
          console.log(error);
        }
      );
    },
    orderCalculate(versionsPriceId) {
      var _this = this;
      _this.upgradeDomain.UpgradeCalculate(
        versionsPriceId,
        function (data) {

          if (data.data.payAmount == 0) {
            _this
              .$confirm(`此次需要支付${data.data.payAmount}元,仅支持余额支付，请确认？`, "升级提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                _this.charge.payment = data.data.payAmount;
                _this.charge.payWay = 0;
                _this.WAndZ=true
                _this.setChargeDialog = true;
              })
              .catch(() => {});
          } else {
            _this
              .$confirm(`你当前版本剩余时间为${data.data.currentDays}天,折合费用为${data.data.currentAmount}。此次升级需要支付${data.data.payAmount}元,请确认是否升级？`, "升级提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                _this.charge.payment = data.data.payAmount;
                _this.setChargeDialog = true;
              })
              .catch(() => {});
          }
        },
        function (error) {
          loading.close();
          console.log(error);
        }
      );
    },
    toCheckOrder() {
      var _this = this;
      let map = {
        attach: "buyMemberPC",
        orderNo: _this.weChatInfo.orderNo,
      };
      var isSuccess = false;
      timer = window.setInterval(() => {
        setTimeout(function () {
          _this.UserDistributorMain.WeChatPayVerificationSuccessPay(
            map,
            function (data2) {
              isSuccess = data2.data.isSuccess;
              if (isSuccess) {
                clearInterval(timer);
                loading.close();
                _this.$message({
                  type: "success",
                  message: "支付成功!",
                });
                // _this.nosucecessloading = false;
                // _this.setChargeDialog = false;
                _this.reload();
              }
            },
            function (error) {
              loading.close();
              console.log(error);
            }
          );
        }, 0);
      }, 5000);
    },
    SaveBtn(formName) {
      var _this = this;
      _this.nosucecessloading = true;
      clearInterval(timer);
      _this.$refs[formName].validate(async (valid) => {
        if (valid) {
          loading = this.$loading({
            lock: true,
            background: "rgba(0, 0, 0, 0.5)",
          });
          let form = {
            userId: _this.userInfo.id,
            payWay: _this.charge.payWay,
            versionsPriceId: _this.dataList[_this.activeIndex].children[_this.activeIndex2].id,
          };
          if (_this.charge.payWay == 0) {
            // 余额支付
            _this.checkUserAccount(form);
          } else if (_this.charge.payWay == 1) {
            // 微信
            _this.submitOrder(form);
          } else {
            // 支付宝
            _this.submitOrder(form);
          }
        } else {
          _this.nosucecessloading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    getUpgradeVersion() {
      var _this = this;
      _this.upgradeDomain.UpgradeVersion(
        function (data) {
          _this.dataList = data.data;
          _this.getMenuList();
        },
        function (err) {
          console.log(err);
        }
      );
    },
    handleVersion(index) {
      this.activeIndex = index;
      this.activeIndex2 = 0;
      this.getMenuList();
    },
    getMenuList() {
      var _this = this;
      let versionId = this.dataList[this.activeIndex].id;
      _this.upgradeDomain.MenuList(
        versionId,
        function (data) {
          let keys = Object.keys(data.data)[0];
          _this.menuList = data.data[keys];
          _this.nameList = [];
          var dataList = ["辨病论治", "辨证论治", "虚证辨证", "体质辨识", "中医诊断", "症状诊断", "检验检测", "中医疾病", "西医疾病", "药品查询", "中药饮片", "中药方剂", "食疗药膳", "健康饮食", "配伍", "视频", "健康常识", "经络穴位", "辞典", "家庭急救"];
          _this.checkMenu(dataList, _this.menuList);
        },
        function (err) {
          console.log(err);
        }
      );
    },
    checkMenu(dataList, menuList) {
      dataList.forEach((item) => {
        let str = item;
        if (item == "中医疾病" || item == "西医疾病") {
          str = "疾病";
        }
        let list = menuList.filter((i) => i.name.indexOf(str) != "-1");
        if (list.length > 0 && list[0].currentlyAvailable && this.nameList.indexOf(str) == -1) {
          this.nameList.push(item);
        }
        menuList.forEach((m) => {
          if (m.children.length > 0) {
            this.checkMenu(dataList, m.children);
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top1 {
  background: #fef3e9;
  border-radius: 6px;
  border: 1px solid #eec08e;
  padding: 5px 10px;
  .t1-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #fef3e9;
    border-radius: 50%;
    border: 1px solid #f54531;
    color: #f54531;
    text-align: center;
  }
  .t1-t {
    margin-left: 10px;
  }
}
.top2 {
  padding: 10px 0;
  font-weight: bold;
  color: #000;
}
.top3 {
  .version-item {
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    border-radius: 6px;
    padding: 8px 30px;
    border: 1px solid #ccc;
    margin-right: 10px;
    cursor: pointer;
    font-size: 15px;
  }
  .activeStyle {
    color: #b77b0b;
    border: 1px solid #eec08e;
  }
}
.top4 {
  margin: 15px 0 0;
  background: #fef3e9;
  border-radius: 6px;
  border: 1px solid #eec08e;
  padding: 10px;
  .t4-1 {
    margin-bottom: 5px;
  }
  .t4-item {
    // margin-right: 15px;
    width: 82px;
  }
}
.top5 {
  .t5-box1 {
    background: #f4f4f4;
    border: 1px solid #cccccc;
    padding: 3px;
    border-radius: 7px;
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    .t5-red {
      width: 80px;
      height: 20px;
      color: #ffffff;
      background: #f64b48;
      border-radius: 0px 0px 10px 10px;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 18px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 50px;
    }
  }
  .t5-box2 {
    background: #f4f4f4;
    border: 1px solid #cccccc;
    padding: 10px;
    border-radius: 8px;
  }
  .t5-box3 {
    padding: 5px;
    .t5-t1 {
      font-weight: bold;
      color: #000;
    }
    .t5-t2 {
      font-size: 12px;
      line-height: 0.9;
    }
    .t5-t4 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
    }
    .t5-t5 {
      font-size: 12px;
      line-height: 0.9;
      text-decoration-line: line-through;
    }
  }
  .activeStyle2 {
    background: #fefdf5;
    border: 1px solid #edd0af;
  }
}
.top6 {
  padding: 10px 0 2px;
  color: #b77b0b;
}
.top7 {
  font-size: 12px;
  .t7-t1 {
    color: #b77b0b;
  }
}
.top8 {
  width: 100%;
  height: 1px;
  background: #e6e6e6;
  margin: 15px 0 10px;
}
.top9 {
  .t9-t1 {
    color: #b77b0b;
    cursor: pointer;
  }

  .t9-btn {
    background: #f64b48;
    border-radius: 6px;
    color: #fff;
    padding: 5px 20px;
    letter-spacing: 1px;
    cursor: pointer;
  }
}
</style>
<style scoped>
@import "../../assets/css/footer.css";

.banner {
  width: 100%;
  height: 160px;
  background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.banner-text {
  float: left;
}

.banner-text h3 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
}

.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}
/**/
.personal-content {
  background: #f7f7f7;
  padding: 40px 0;
}
.personal-box {
  width: 1200px;
  margin: 0 auto;
}
.personal-left {
  float: left;
}
.personal-right {
  float: right;
  width: 900px;
  background: #ffffff;
  box-sizing: border-box;
}
.personal-right-tit {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;
}
</style>
