let Upgrade = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  this.UpgradeVersion = function (success, error) {
    var url = ServiceBaseAddress + "/api/VersionsPrice/Applet/UpgradeVersion";
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.MenuList = function (versionsId, success, error) {
    var url = ServiceBaseAddress + `/api/Versions/MenuList/${versionsId}`;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.UnifiedorderBrowser = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/WeChatPay/UnifiedorderBrowser";
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.UpgradeCalculate = function (versionsPriceId, success, error) {
    var url = ServiceBaseAddress + "/api/WeChatPay/UpgradeCalculate/" + versionsPriceId;
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
}

export { Upgrade };
